<template>
	<div class="pages-index">

		<!-- 特效开关 -->
		<!-- <vue-particles color="#8EB5C9" :particleOpacity="0.9" :particlesNumber="80" shapeType="circle" :particleSize="6"
			linesColor="#8EB5C9" :linesWidth="1" :lineLinked="true" :lineOpacity="0.6" :linesDistance="150"
			:moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="all-page">
		</vue-particles> -->



		<!-- 顶部Banner -->
		<div class="carousel-banner">
			<router-link :to="{ name: 'aboutUS', params: { a:'0'}}">
				<swiper :options="swiperOptionBanner">
					<swiper-slide class="">
						<img class="img-ph" style="width: 100%;" :src="require('@/assets/image/home_topph.png')" />
						<div class="top-css1 img-pc">
							<img class="img-bg" :src="require('@/assets/image/home_top.png')" />
							<div class="c1">
								<img :src="require('@/assets/image/home_top_title.png')" />
								<div class="c1-title">标准化开发体系为您保驾护航</div>
								<div class="c1-en">Standardized development system</div>
								<p>
									八年磨一剑，德合拥有30余项软件著作权，与超过60家企业达成战略合作关系，累计服务客户超过1500家。立足于行业洞察，以软件研发为核心，依托自主研发的软件产品CMS、CRM、BPM、SHOP、BI等，为企业提供更专业、更全面、更保障信息化建设服务，我们将利用创新的“互联网+技术”推动社会发展与变革，提升企业的核心竞争力，促进社会的高效运作。
								</p>
								<div class="c1-more">查看更多</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide class="">
						<div class="top-css2">
							<img class="img-pc" :src="require('@/assets/image/home_top01.png')" />
							<img class="img-ph" :src="require('@/assets/image/home_top01ph.png')" />
						</div>

					</swiper-slide>
					<swiper-slide class="">
						<img class="img-ph" style="width: 100%;" :src="require('@/assets/image/home_top02ph.png')" />
						<div class="top-css3 img-pc">
							<img class="img-pc" :src="require('@/assets/image/home_top02.png')" />
							<div class="c3">
								<div class="c3-title">高效开发流程</div>
								<div class="c3-en">Efficient development process</div>
								<p>
									标准的开流程对软件开发至关重要，德合信息摸索了一套自己的高质量标准开发规范及流程。为每一个项目配备一个专业的项目负责人，全称跟踪项目进度，充分联动客户与技术人员。保障每个项目按质按量收尾。
								</p>
								<div class="c3-more">查看更多</div>
							</div>
						</div>
					</swiper-slide>

					<!-- <div class="swiper-pagination swiper-banner-pagination" slot="pagination"></div> -->
				</swiper>
			</router-link>

			<div class="home-top">
				<el-col class="card-show" :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24, offset: 0 }"
					:lg="{ span: 24, offset: 0}">
					<swiper :options="swiperOptionCard">
						<!-- slides -->
						<swiper-slide v-for="(item,index) in swiperList" :key="index">
							<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
							<div class="item wow slideInUp" data-wow-delay="0s">
								<router-link :to="{ name: 'cooperate', params: { chooseTab: item.index}}">
									<div class="img-box">
										<img class="s-img" :src="item.img" />
										<div class="title">{{item.title}}</div>
										<div class="more-css">查看更多</div>
									</div>
								</router-link>
							</div>
							<!-- </a> -->
						</swiper-slide>

						<!-- Optional controls -->
						<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div>
						<div class="swiper-button-prev swiper-card-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next swiper-card-button-next" slot="button-next"></div> -->
					</swiper>
				</el-col>
			</div>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="bgbox-white" style="width: 100%;">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:16,offset:4}">
				<div class="module1-conts" style="margin-bottom: 50px;">
					<div class="module-title">
						<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
						<div style="margin: 0 5px;">德合视频</div>
						<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
					</div>
				</div>
				<video controls width="100%" poster="@/assets/image/video_top.png" >
					<source src="http://ttf.hunandehe.com/xcp.mp4" type="video/mp4">
				</video>
			</el-col>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="bgbox-white" style="width: 100%;">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}">
				<div class="module1-conts">
					<div class="module-title">
						<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
						<div style="margin: 0 5px;">优势介绍</div>
						<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
					</div>
					<div class="img-pc" style="position: relative;margin: 30px 0 120px;">
						<img style="width: 100%;" :src="require('@/assets/image/home_bg1.png')" />
						<img style="position: absolute;top: 22%;left: 4%; width: 92%;"
							:src="require('@/assets/image/home_bg2.png')" />
					</div>
					<div class="img-ph">
						<div class="ysjs-css" v-for="(item,index) in ysjsList" :key="index">
							<img :src="item.img" />
							<div class="ysjs-title">
								{{item.title}}
							</div>
							<p>{{item.p}}</p>
						</div>
					</div>

				</div>
			</el-col>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="ywfw">
			<img class="ywfw-img img-pc" :src="require('@/assets/image/home_bg.png')" />
			<img class="img-ph ywfw-img" :src="require('@/assets/image/home_icon01ph.png')" />
			<div style="position: absolute;top: 5%;width: 100%;color: #FFFFFF;">
				<div class="module-title">
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
					<div style="margin: 0 5px;">业务范围</div>
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
				</div>
				<div style="width: 100%;text-align: center;font-size: 14px;margin-top: -15px;">“互联网+技术”</div>
				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:16,offset:4}">
					<el-col :xs="{span:24}" :sm="{span:12}" :md="{span:12,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="ywfw-css">
							<img class="" :src="require('@/assets/image/home_icon_ywfw1.png')" />
							<img class="" :src="require('@/assets/image/home_icon_ywfw01.png')" />
							<div class="ywfw-title">定位服务</div>
							<p>APP开发</p>
							<p>微信开发</p>
							<p>软件开发</p>
							<p>小程序开发</p>
							<p>网站建设推广</p>
						</div>
					</el-col>
					<el-col :xs="{span:24}" :sm="{span:12}" :md="{span:12,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="ywfw-css">
							<img class="" :src="require('@/assets/image/home_icon_ywfw2.png')" />
							<img class="" :src="require('@/assets/image/home_icon_ywfw02.png')" />
							<div class="ywfw-title">产品服务</div>
							<p>全能商城系统</p>
							<p>云建站系统</p>
							<p>AI智能名片</p>
							<p>5合1小程序</p>
							<p>同城外卖系统</p>
						</div>
					</el-col>
					<el-col :xs="{span:24}" :sm="{span:12}" :md="{span:12,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="ywfw-css">
							<img class="" :src="require('@/assets/image/home_icon_ywfw3.png')" />
							<img class="" :src="require('@/assets/image/home_icon_ywfw03.png')" />
							<div class="ywfw-title">解决方案</div>
							<p>电商直播解决方案</p>
							<p>行业互联网+方案</p>
							<p>CRM客户管理系统</p>
							<p>网络营销推广方案</p>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="bgbox-white" style="width: 100%;">
			<div class="module1-conts">
				<div class="module-title">
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
					<div style="margin: 0 5px;">开发流程</div>
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
				</div>
				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:20,offset:2}"
					style="margin-top: 30px;text-align: center;">
					<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="kflc-css">
							<img :src="require('@/assets/image/home_icon_bg1.png')" />
							<div class="kflc-box">
								<div class="title">项目诊断</div>
								<p>专业产品经理免费提供项目咨询和建议，为您提供最专业的解决方案</p>
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="kflc-css">
							<img style="" :src="require('@/assets/image/home_icon_bg2.png')" />
							<div class="kflc-box">
								<div class="title">开发方案</div>
								<p>为您提供系统的开发方案，需求文档+开发文档+服务承诺</p>
							</div>

						</div>
					</el-col>
					<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="kflc-css">
							<img style="" :src="require('@/assets/image/home_icon_bg3.png')" />
							<div class="kflc-box">
								<div class="title">产品原型</div>
								<p>UI设计师为您设计好界面效果，降低沟通受阻率，提高开发效率</p>
							</div>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="hzhb">
			<div class="module-title">
				<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
				<div style="margin: 0 5px;">合作伙伴</div>
				<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
			</div>
			<div style="margin-top: 50px;">
				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:20,offset:2}"
					class="col-pd">
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb1.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb2.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb3.png')" />
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb4.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb5.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb1-6/home_hzhb6.png')" />
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb7.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb8.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb9.png')"
									style="margin-left: -10px;" />
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						class="mgt-css" style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb10.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb11.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb7-12/home_hzhb12.png')" />
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						class="mgt-css" style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb13.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb14.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb15.png')" />
							</div>
						</div>
					</el-col>
					<el-col :xs="{span:8}" :sm="{span:8}" :md="{span:8,offset:0}" :lg="{span:4,offset:0}"
						class="mgt-css" style="display: flex;justify-content: center;">
						<div class="hzhb-css">
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb16.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb17.png')" />
							</div>
							<div class="hzhb-img">
								<img :src="require('@/assets/image/hzhb13-18/home_hzhb18.png')" />
							</div>
						</div>
					</el-col>
				</el-col>
			</div>
		</div>



	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'



	export default {
		components: {
			swiper,
			swiperSlide

		},
		data() {

			return {
				swiperList: [
				// 	{
				// 	index: 0,
				// 	img: require('@/assets/image/home_icon1.png'),
				// 	title: 'zxmAPP',
				// }, 
				{
					img: require('@/assets/image/home_icon2.png'),
					title: '盼盼木业APP',
				}],
				ysjsList: [{
					index: 1,
					img: require('@/assets/image/home_ysjs1.png'),
					title: '免费需求诊断',
					p: '拥有一大批优秀的产品经理，我们可以在项目开发前为您免费提供需求整理及咨询策划服务，并为每个客户出具《项目开发方案》及《软件报价清单》，让您在软件开发上少走弯路少花钱。'
				}, {
					img: require('@/assets/image/home_ysjs2.png'),
					title: '全面周边服务',
					p: '我们不仅仅为客户提供开发服务，软件本身需要大量的第三方服务商做配合支撑，德合信息与所有市面上的主流三方服务商保持良好的合作关系，为您提供一站式软件开发服务。'
				}, {
					img: require('@/assets/image/home_ysjs3.png'),
					title: '实战经验丰富',
					p: '我们不仅仅为客户提供开发服务，软件本身需要大量的第三方服务商做配合支撑，德合信息与所有市面上的主流三方服务商保持良好的合作关系，为您提供一站式软件开发服务。'
				}, {
					img: require('@/assets/image/home_ysjs4.png'),
					title: '完善售后服务',
					p: '每个项目立项我们都会建立项目开发小组交流群，方便客户与开发小组成员灵活沟通，项目完成后此群组成为项目售后交流群，此外还会为您配备一个项目经理作为专属顾问。'
				}, {
					img: require('@/assets/image/home_ysjs5.png'),
					title: '技术实力过硬',
					p: '我们不断优化自身技术框架，吸纳优秀的软件工程师。先后获得湖南省高新技术企业，双百企业并通过ISO体系质量认证。共拥有25+软件著作权并与湘潭大学、步步高集团等超过60个大型企业保持长期合作。'
				}, {
					img: require('@/assets/image/home_ysjs6.png'),
					title: '高效开发流程',
					p: '标准的开流程对软件开发至关重要，德合信息摸索了一套自己的高质量标准开发规范及流程。为每一个项目配备一个专业的项目负责人，全称跟踪项目进度，充分联动客户与技术人员。保障每个项目按质按量收尾。'
				}],
				showBigImg: false,
				bigImgSrc: null,
				swiperOptionBanner: {
					autoHeight: true,
					slidesPerView: 1, //显示数量
					loop: true,
					autoplay: {
						delay: 3000,
						disableOnInteraction: false //点击切换后 停止继续自动播放
					},
					effect: 'fade', //渐变
					pagination: {
						el: '.swiper-banner-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-banner-button-next',
						prevEl: '.swiper-banner-button-prev',
					}
				},
				swiperOptionCard: {
					slidesPerView: 3.5, //显示数量
					spaceBetween: -80,
					centeredSlides: true, //居中slide
					centeredSlidesBounds: true,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					loop: true,
					autoplay: {
						delay: 3000,
						disableOnInteraction: false //点击切换后 停止继续自动播放
					},
					breakpoints: {
						640: {
							slidesPerView: 1.3,
							spaceBetween: -5,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						992: {
							slidesPerView: 3,
							spaceBetween: 20,
						}
					},
					pagination: {
						el: '.swiper-card-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-card-button-next',
						prevEl: '.swiper-card-button-prev',
					}
				},
			}
		},
		mounted() {

		},
		methods: {

		}
	};
</script>

<style lang="scss">
	.home-top {
		background-color: rgba(8, 11, 23, 1);
		height: 550px;
		margin-top: -50px;
		.card-show {
			overflow: hidden;
			width: 100%;
			

			.item {
				float: left;
				width: 100%;
				margin: 0px 0px 0px 0px;
				padding: 10px 10px 10px 10px;
				box-sizing: border-box;
				transition: all 0.3s ease;

				p {
					height: 60px;
					font-size: 16px;
					color: #666;
					margin: 20px 0;
				}
			}

			.item:hover {
				cursor: pointer;
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				transform: translate3d(0, -2px, 0);

				img {
					// transform: scale(1.1);
				}
			}

			.item:last-child {
				margin-right: 0;
			}

			.swiper-button-prev,
			.swiper-button-next {
				outline: none;
				height: 30px;
			}
		}
	}

	.swiper-slide {
		opacity: 0.1;
		color: #fff;

		.img-box {
			width: 100%;
			// max-height: 460px;
			overflow: hidden;
			color: #FFFFFF;
			text-align: center;

			img {
				width: 50%;
				// height: 200px;
				object-fit: cover;
				transition: all 0.3s ease;
			}

			.title {
				text-align: left;
				margin-top: 30px;
				display: none;
			}

			.more-css {
				border: 1px solid #FFFFFF;
				margin-top: 10px;
				border-radius: 50px;
				width: 120px;
				padding: 3px 7px;
				text-align: center;
				font-size: 20px;
				letter-spacing: 0.27em;
				display: none;
			}
		}
	}

	.swiper-slide-active {
		opacity: 1;
		color: #fff;

		.img-box {
			width: 100%;
			// max-height: 460px;
			overflow: hidden;
			color: #FFFFFF;

			img {
				width: 90%;
				// height: 351px;
				object-fit: cover;
				transition: all 0.3s ease;
			}

			.title {
				text-align: left;
				margin-top: 30px;
				margin-left: 5%;
				display: block;
			}

			.more-css {
				border: 1px solid #FFFFFF;
				margin-top: 10px;
				margin-left: 5%;
				border-radius: 50px;
				width: 120px;
				padding: 3px 7px;
				text-align: center;
				font-size: 20px;
				letter-spacing: 0.27em;
				display: block;

			}
		}

		.img-box:hover {

			img:hover {
				width: 100%;
				// height: 351px;
				object-fit: cover;
				transition: all 0.3s ease;
			}

			.title {
				margin-left: 0%;

			}

			.more-css {
				margin-left: 0%;
			}
		}

	}

	// banner swiper
	.carousel-banner {
		.swiper-pagination {
			display: flex;
			align-items: center;
			position: absolute;
			width: auto;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 11px;
			padding: 6px 16px;
		}

		.swiper-pagination-bullet {
			width: 6px !important;
			height: 6px !important;
			background: #fff !important;
			opacity: 0.8;
		}

		.swiper-pagination-bullet-active {
			background: transparent !important;
			border: 2px solid #fff !important;
		}
	}

	.swiper-pagination {
		display: flex;
		align-items: center;
		position: absolute;
		width: auto;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 11px;
		padding: 6px 16px;
	}

	.swiper-pagination-bullet {
		width: 6px !important;
		height: 6px !important;
		background: #fff !important;
		opacity: 0.8;
	}

	.swiper-pagination-bullet-active {
		background: transparent !important;
		border: 2px solid #fff !important;
	}



	#particles-js {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}

	#particles-content {
		position: absolute;
		width: 100%;
		top: 0%;
		bottom: 10%;

	}


	#particles-content .big-title {
		width: 100%;
		height: 70%;
		// background-image: url(../../assets/images/top_banner2X_title.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 50% 50%;
	}


	#particles-content .desc-title {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 32px;
		height: 30%;
	}

	#particles-content .desc-title-top {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 62px;
		font-weight: 800;
		height: 50%;
	}

	.top-page {
		background-size: cover;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		width: 818;
		height: 869;
		overflow: hidden;
	}

	.all-page {
		background-size: cover;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
</style>

<style lang="scss" scoped>
	.carousel-banner {
		width: 100%;
		position: relative;

		.swiper-container {
			height: 100%;
			width: 100%;
		}

		.swiper-slide .banner-img {
			width: 100%;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}
	}


	.bgbox-white {
		padding: 50px 0;
		overflow: hidden;
		background: #fff;
	}

	.bgbox-gray {
		padding: 50px 0;
		overflow: hidden;
		background: #f7f7f7;
	}

	.module-title {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		line-height: 1;
		padding: 50px 0 30px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.top-css1 {
		background-color: #080B17;
		color: #fff;
		position: relative;
		height: 102%;

		.img-bg {
			width: 100%;
			margin-top: 22%;
		}



		.c1 {
			width: 55%;
			position: absolute;
			top: 20%;
			left: 10%;

			img {
				width: 90%;

			}

			.c1-title {
				font-size: 50px;
			}

			.c1-en {
				font-size: 36px;
				letter-spacing: 0.065em;
			}

			.c1-more {
				border: 1px solid #FFFFFF;
				margin-top: 30px;
				border-radius: 50px;
				width: 150px;
				padding: 3px 7px;
				text-align: center;
				font-size: 24px;
				letter-spacing: 0.27em;

			}

			p {
				margin-top: 5px;
				width: 100%;
				font-size: 12px;
				letter-spacing: 0.27em;
			}
		}
	}

	.top-css2 {
		height: 100%;

		img {
			width: 100%;
		}

	}

	.img-ph {
		display: none;
	}

	.top-css3 {
		background-color: #080B17;
		color: #fff;
		position: relative;
		height: 100%;

		img {
			width: 100%;
		}

		.c3 {
			width: 45%;
			position: absolute;
			top: 28%;
			right: 10%;

			.c3-title {
				font-size: 50px;
			}

			.c3-en {
				font-size: 36px;
				letter-spacing: 0.065em;
			}

			.c3-more {
				border: 1px solid #FFFFFF;
				margin-top: 30px;
				border-radius: 50px;
				width: 150px;
				padding: 3px 7px;
				text-align: center;
				font-size: 24px;
				letter-spacing: 0.27em;
			}

			p {
				margin-top: 10px;
				font-size: 12px;
				letter-spacing: 0.27em;
			}
		}
	}


	.ywfw {
		position: relative;
		// margin-bottom: 50px;

		.ywfw-img {
			width: 100%;
		}
	}

	.ywfw-css {
		text-align: center;
		margin-top: 50px;
		padding: 10px 40px;

		.ywfw-title {
			font-weight: 700;
			font-size: 24px;
			margin-bottom: 20px;
		}

		img {
			display: block;
			height: 150px;
		}

		img:last-of-type {
			display: none;
		}


		p {
			font-size: 18px;
			line-height: 30px;

		}

		&:hover {
			img {
				display: none;

			}

			img:last-of-type {
				display: block;
				height: 150px;
			}

			height: 360px;
			transform: translate3d(0, -2px, 0);
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			background: linear-gradient(180deg, #7B9FFF 0%, #225DF6 58.12%, #225DF6 100%);
			box-shadow: 0px 4px 51px rgba(153, 153, 153, 0.15);

		}
	}

	.kflc-css {
		position: relative;
		color: #fff;
		width: 100%;
		height: 200px;
		// border: 1px solid #00ff00;
		// background-color: #000;
		padding: 10px;

		img {
			// max-width: 390px;
			// max-height: 200px;
			width: 98%;
			height: 100%;
			object-fit: cover;
		}

		.kflc-box {
			position: absolute;
			top: 10%;
			left: 10%;
			right: 10%;

			.title {
				font-weight: 700;
				font-size: 24px;
				margin-bottom: 20px;
			}

			p {
				text-align: left;
				font-size: 18px;
				line-height: 34px;
			}

		}


	}

	.hzhb {
		background-color: #080B17;
		color: #FFFFFF;
		padding-top: 50px;
		height: 600px;
	}

	.hzhb-css {
		// border: 1px solid #0f0;
		width: 80%;
		height: 408px;

		// padding: 10px;
		.hzhb-img {
			background: #FFFFFF;
			width: 100%;
			height: 97px;
			line-height: 94px;
			text-align: center;
			margin-top: 30px;

			img {
				max-width: 100%;
				max-height: 96px;
			}
		}
	}


	// 移动端css
	@media screen and (max-width: 767px) {
		.ysjs-css {
			padding: 30px 20px;
			text-align: center;

			img {
				width: 100px;
			}

			.ysjs-title {
				margin-top: 20px;
				font-size: 22px;
				font-weight: 600;
			}

			p {
				margin-top: 20px;
				text-align: left;
				font-size: 16px;
				color: #ABABAB;
			}
		}

		.top-css1 {
			background-color: #080B17;
			color: #fff;
			position: relative;

			.img-bg {
				margin-top: 95%;
				width: 100%;
				object-fit: cover;
			}

			.c1 {
				width: 90%;
				position: absolute;
				top: 2%;
				left: 5%;
				right: 5%;

				img {
					width: 100%;
					margin-top: 25%;
				}

				.c1-title {
					font-size: 26px;
				}

				.c1-en {
					font-size: 18px;
					letter-spacing: 0.065em;
				}

				.c1-more {
					border: 1px solid #FFFFFF;
					margin-top: 30px;
					border-radius: 50px;
					width: 30%;
					padding: 3px 7px;
					text-align: center;
					font-size: 14px;
					letter-spacing: 0.27em;
				}

				p {
					margin-top: 10px;
					font-size: 12px;
					letter-spacing: 0.27em;
				}
			}
		}

		.top-css2 {
		

			img {
				width: 100%;
				// height: 870px;
				object-fit: cover;
			}

		}

		.img-pc {
			display: none;
		}

		.img-ph {
			display: block;
		}

		.top-css3 {
			background-color: #080B17;
			color: #fff;
			position: relative;

			img {
				width: 100%;
			}

			.c3 {
				width: 90%;
				position: absolute;
				top: 25%;
				left: 5%;
				right: 5%;

				.c3-title {
					font-size: 40px;
				}

				.c3-en {
					font-size: 20px;
					letter-spacing: 0.065em;
				}

				.c3-more {
					border: 1px solid #FFFFFF;
					margin-top: 30px;
					border-radius: 50px;
					width: 30%;
					padding: 3px 7px;
					text-align: center;
					font-size: 14px;
					letter-spacing: 0.27em;
				}

				p {
					font-size: 12px;
					letter-spacing: 0.27em;
				}
			}
		}

		.home-top {
			// background-color: #080B17;
			height: 350px;
			margin-top: -280px;

			.card-show {
				overflow: hidden;
				width: 100%;

				.item {
					float: left;
					width: 100%;
					margin: 0px 0px 20px 0px;
					// padding: 0px 10px 20px 10px;
					padding: 0px 10px 20px 0px;
					box-sizing: border-box;
					transition: all 0.3s ease;
				}

				.item:hover {
					cursor: pointer;
					// box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
					transform: translate3d(0, -2px, 0);

					img {
						transform: scale(1.1);
					}
				}

				.item:last-child {
					margin-right: 0px;
				}

				.swiper-button-prev,
				.swiper-button-next {
					outline: none;
					height: 30px;
				}
			}
		}

		.swiper-slide {
			opacity: 0.1;
			color: #fff;

			.img-box {
				width: 100%;
				// height: 460px;
				overflow: hidden;
				color: #FFFFFF;

				img {
					width: 270px;
					height: 100px;
					object-fit: cover;
					transition: all 0.3s ease;
				}
			}
		}

		.swiper-slide-active {
			opacity: 1;
			color: #fff;

			.img-box {
				// width: 100%;
				// height: 460px;
				overflow: hidden;
				color: #FFFFFF;

				img {
					width: 100%;
					height: 200px;
					object-fit: cover;
					transition: all 0.3s ease;
				}

				.title {
					text-align: left;
					margin-top: 30px;
					display: block;
				}

				.more-css {
					border: 1px solid #FFFFFF;
					margin-top: 20px;
					border-radius: 50px;
					width: 30%;
					padding: 3px 7px;
					text-align: center;
					font-size: 14px;
					letter-spacing: 0.27em;
					display: block;

				}
			}
		}

		.carouse2-banner {
			display: block;

			.swiper-pagination {
				display: flex;
				align-items: center;
				position: absolute;
				width: auto;
				bottom: 20px;
				left: 50%;
				transform: translateX(-50%);
				background-color: rgba(0, 0, 0, 0.1);
				border-radius: 11px;
				padding: 6px 16px;
			}

			img {
				width: 100%;
			}
		}

		.module-title {
			padding: 30px 0 30px;
			text-align: center;
			font-size: 26px;
			font-weight: bold;
			line-height: 1;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		.ywfw {
			position: relative;
			margin-bottom: 50px;
		}

		.ywfw-css:first-child {
			margin-top: 30px;
		}

		.ywfw-css {
			padding: 15px 50px;
			text-align: center;

			height: 260px;
			margin: 5px 0;

			.ywfw-title {
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 10px;
			}

			img {
				text-align: center;
				height: 100px;
			}

			p {
				font-size: 14px;
				line-height: 25px;

			}

			&:hover {

				img {
					display: none;

				}

				img:last-of-type {
					display: block;
					height: 100px;
				}

				height: 260px;
				transform: translate3d(0, -2px, 0);
				box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
				border-radius: 20px;
				background: linear-gradient(180deg, #7B9FFF 0%, #225DF6 58.12%, #225DF6 100%);
				box-shadow: 0px 4px 51px rgba(153, 153, 153, 0.15);

			}
		}

		.mgt-css {
			margin-top: -78px;
		}

		.hzhb {
			background-color: #080B17;
			padding-top: 50px;
			height: 880px;
		}

		.col-pd {
			padding: 0 10px;
		}

		.hzhb-css {

			width: 88%;
			height: 408px;

			// padding: 10px;
			.hzhb-img {
				background: #FFFFFF;
				width: 100%;
				height: 97px;
				line-height: 94px;
				text-align: center;
				margin-top: 13px;

				img {
					max-width: 100%;
					max-height: 96px;
				}
			}
		}


		.kflc-css {
			position: relative;
			color: #fff;
			width: 90%;
			height: 200px;
			// border: 1px solid #00ff00;
			// background-color: #000;
			padding: 0px;
			margin-bottom: 20px;
			text-align: left;

			img {
				// max-width: 390px;
				// max-height: 200px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}


	// 991
	@media screen and (max-width: 991px) {
		.flex-row {
			display: block;
		}
	}
</style>
